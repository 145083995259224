$(document).ready(function () {

	var modalSwiper, pageSwiper, portfolioSwiper;

	// -------------------------- F

	function createMainNav() {
		var $nested = $('#mainnav .nested');
		$('.swiper-slide.project').each(function (idx) {

			var title = $(this).find('.projectname').text();
			var linkString = formatNrString(idx + 1);
			var link = '<a href="" class="portnav" data-page="1" data-port="' + idx + '" title="' + title + '">' + linkString + '</a>';
			$nested.append(link);

		});
	}

	function formatNrString(d) {
		return (d < 10) ? '0' + d.toString() : d.toString();
	}

	function portnavSetActive() {
		var activePort = portfolioSwiper.activeIndex;
		var totalSl = portfolioSwiper.slides.length;
		$('.portnav').removeClass('active');
		$('.portnav').eq(activePort).addClass('active');
		$('.arrow').removeClass('hidden');
		if (activePort < 1) {
			$('.arrow.prev').addClass('hidden');
		} else if (activePort + 1 === totalSl) {
			$('.arrow.next').addClass('hidden');
		}
	}

	function setVH() {
		var vh100 = $(window).height();
		$('.vh100').css('height', vh100);
	}

	function showScrollDown() {
		setTimeout(function () {
			$('.scrolldown').addClass('active');
		}, 2000)
	}

	$('html').removeClass('no-js');

	setVH();
	showScrollDown();

	createMainNav();

	$(window).resize(function () {
		setVH();
	});

	// -------------------------- SWIPER

	pageSwiper = new Swiper('.swiper-pages', {
		speed: 200,
		direction: 'vertical',
		mousewheel: true,
		keyboard: true,
		on: {
			slideChangeTransitionStart: function (swiper, direction) {
				$('.scrolldown').removeClass('active');
			},
			slideChangeTransitionEnd: function (swiper, direction) {
				var actPage = pageSwiper.activeIndex + 1;
				$('.pagenav').removeClass('active');
				$('.pagenav:nth-of-type(' + actPage + ')').addClass('active');
				$('.portnav').removeClass('active');
				showScrollDown();
				if (actPage == 2) {
					portnavSetActive();
				}
			}
		}

	});

	$('.wrap').hover(
		function () {
			pageSwiper.mousewheel.disable();
		},
		function () {
			pageSwiper.mousewheel.enable();
		}
	);

	portfolioSwiper = new Swiper('.swiper-portfolio', {
		speed: 200,
		direction: 'horizontal',
		keyboard: true,
		pagination: {
			el: '#pagination',
			clickable: true
		},
		on: {
			slideChangeTransitionEnd: function (swiper, direction) {
				portnavSetActive();
			}
		},
		preloadImages: false,
		lazy: {
			loadPrevNext: true,
			preloaderClass: 'swiper-lazy-elektroloader'
		}
	});

	function initModalSwiper(pid) {

		if (modalSwiper) {
			modalSwiper.destroy();
			$('.modal_prev').off();
			$('.modal_next').off();
		}

		portfolioSwiper.keyboard.disable();

		modalSwiper = new Swiper('.swiper-modal', {
			speed: 200,
			loop: true,
			keyboard: true,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
				preloaderClass: 'swiper-lazy-elektroloader'
			},
			pagination: {
				el: '.mod_pagination',
				clickable: true
			},
			zoom: {
				maxRatio: 3
			}
		});
		
//		modalSwiper.on('slideChange', function() {
//			var colors = ['red', 'green', 'blue', 'white'];
//			var rand = Math.floor(Math.random() * Math.floor(4));
//			$('#modal .zoom-info').css('color', colors[rand]);
//		});

		$('.modal_prev').on('click', function () {
			modalSwiper.slidePrev();
		});

		$('.modal_next').on('click', function () {
			modalSwiper.slideNext();
		});
	}

	function removeModalSwiper() {

		portfolioSwiper.keyboard.enable();

		$('#modal').empty().removeClass('open');
		$('#menu').removeClass('closemodal');
		$('.m-hide').removeClass('visuallyhidden');

	}

	// ------------------------------ ANIMATION

	$('.blitz').click(function (e) {
		e.preventDefault();
		$('#bolt').toggleClass('color');
		$('.round1').toggleClass('color');
	});

	$('.stern').click(function (e) {
		e.preventDefault();
		$('#star').toggleClass('color');
		$('.round2').toggleClass('color');
	});

	$('.tablet').click(function (e) {
		e.preventDefault();
		$('#tablet').toggleClass('color');
		$('.round3').toggleClass('color');
	});

	// ------------------------------ NAVIGATION

	$('.elicht').click(function (e) {
		e.preventDefault();
		pageSwiper.slideTo(0);
	});

	$('.pagenav').click(function (e) {
		e.preventDefault();
		var pageIndex = $(this).data('page');
		pageSwiper.slideTo(pageIndex);
	});

	$('.portnav').click(function (e) {
		e.preventDefault();
		var pageIndex = $(this).data('page');
		var slideIndex = $(this).data('port');
		pageSwiper.slideTo(pageIndex);
		portfolioSwiper.slideTo(slideIndex);
	});

	$('.scrolldown').click(function (e) {
		e.preventDefault();
		pageSwiper.slideNext();
	});

	$('.page_left').click(function (e) {
		e.preventDefault();
		portfolioSwiper.slidePrev();
	});

	$('.page_right').click(function (e) {
		e.preventDefault();
		portfolioSwiper.slideNext();
	});

	// ------------------------------ MENU / NAV 

	$('#menu').click(function (e) {
		e.stopPropagation();

		$('.round').removeClass('color');
		$('#bolt').removeClass('color');
		$('#star').removeClass('color');
		$('#tablet').removeClass('color');
		if ($(this).hasClass('closemodal')) {

			removeModalSwiper();

		} else if ($(this).hasClass('close')) {
			$('#mainnav').removeClass('open');
			$(this).removeClass('close');
		} else if (!$(this).hasClass('closemodal') && !$(this).hasClass('close')) {
			$('#mainnav').addClass('open');
			$(this).addClass('close');
		}
		return false;
	});

	$('html').click(function (e) {
		var isMe = $(e.target);
		if (!isMe.is('a')) {
			$('#mainnav').removeClass('open');
			$('#menu').removeClass('close');
			$('.round').removeClass('colored');
		}
	});

	// -------------------------- MODAL

	$('.details').click(function (e) {
		e.preventDefault();

		var pid = $(this).data('pid');
		var url = 'portfolio/' + pid + '.html' + ' #' + pid;

		$('#modal').addClass('open');
		$('#menu').addClass('closemodal').removeClass('close');
		$('#mainnav').removeClass('open');
		$('.m-hide').addClass('visuallyhidden');

		$('#modal').load(url, function () {
			
			$('#modal .swiper-container').append('<div class="zoom-info">Double tap image to zoom</div>');

			initModalSwiper(pid);

		});
	});

	$('#modal').on('click', function (e) {
		e.stopPropagation();
		var $tar = $(e.target);
		if ($tar.hasClass('swiper-slide-active') || $tar.hasClass('imgpadd')) {
			removeModalSwiper();
		}

	});


	// -------------------------- END
});
